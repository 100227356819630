@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-fluid .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
    padding: 0px;
  }
}

html,
body {
  height: 100% !important;

  font-family: 'Inter', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 180% !important;

  margin: 0px !important;
  padding: 0px !important;
}


html::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;

}

html::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.full_h {
  height: 100% !important;
}

.auto_h {
  height: calc(100% - 82px) !important;
}

// sass range to generate gap classes to 50 step 5
$a: 5;

@for $i from 5 through 10 {
  .gap-#{$a} {
    gap: #{$a}px !important;
  }

  $a: $a + 5;
}

// range to generate margin right classes to 50 step 5
$a: 5;

@for $i from 5 through 10 {
  .mr-#{$a} {
    margin-right: #{$a}px !important;
  }

  .ml-#{$a} {
    margin-left: #{$a}px !important;
  }

  $a: $a + 5;
}

// range to generate width classes to 5 to 100
$a: 5;

@for $i from 5 through 24 {
  .w-#{$a} {
    width: #{$a}#{'%'} !important;
  }

  $a: $a + 5;
}

.bold-hr {
  border-color: black;
  opacity: 1;
}

.green {
  color: #77D19B !important;
}

.red {
  color: #FF0000 !important;
}

.gray {
  color: #7A7A7A !important;
}


.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.pointer {
  cursor: pointer;
}

.heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;

  color: #000000;
}

.required {
  &::after {
    content: "*";
    color: red;
  }
}

.dark {
  background-color: #121212 !important;
  color: #f7ffe8 !important;

  .heading {
    color: white !important;
  }

  .required {
    &::after {
      color: #f7ffe8 !important;
    }
  }

  hr {
    border-color: #f7ffe8 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #f7ffe8 !important;
  }

  a {
    //color: #f7ffe8 !important;
  }

  .row {
    background-color: #121212 !important;
  }

  textarea,
  input,
  select {
    background-color: #222222 !important;
    border-color: #414141 !important;
  }

  .bi-moon {
    color: #f7ffe8 !important;
  }

  svg {
    path {
      &:last-child {
        fill: #f7ffe8 !important;
      }

    }

    color: white !important;
  }

  p {
    color: #f7ffe8 !important;
  }

  .renderer {
    background-color: #222222 !important;
  }
}

.progress {
  height: 2rem !important;
  position: relative;

  .p-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 500;
  }
}

.heart {
  animation: fadeinout 1s infinite;
}

.heart:before {
  animation-delay: 0.5s;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}