@import '../../../styles/variables';

nav {
    margin-top: 39px;

    .logo {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: center;

        color: #000000;
    }

    .theme_icon{
        font-size: 24px;
        margin-left: 10px;
        cursor: pointer;
        background-color: transparent;
        border: 0;
    }

    .upload_button {
        border: 2px solid $stroke-color;
        border-radius: 104px;
        padding: 10px;
        min-width: 142px;
        text-align: center;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #7774FA;

        text-decoration: none;

        cursor: pointer;

        &:hover{
            background-color: $stroke-color;
            color: $button-bg-color;
        }
    }
}