.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: justify;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.header {
    margin-bottom: 20px;
}

.header h1 {
    color: #333;
    font-size: 2em;
    text-align: center;
}

.body {
    margin-bottom: 20px;
}

.body p {
    margin-bottom: 20px;
    font-size: 1.1em;
    color: #555;
}

.footer {
    text-align: center;
}